import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import NavHeader from '../components/products/NavHeader'
import ShViewer from '../components/products/sh/ShViewer'
import ContextConsumer from '../components/Context'

const StrawberryHaze = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <ContextConsumer>
      {({ data, set }) => (
    <Page>
      <Container>
        <NavHeader productName={'strawberry haze'}/>
        <ShViewer items={data.cartItems} productName={'STRAWBERRY HAZE'} />
      </Container>
    </Page>

      )}
    </ContextConsumer>
  </IndexLayout>
)

export default StrawberryHaze
